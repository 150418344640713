@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "gotham-body";
  src: url("../public/fonts/Gotham-Book.otf") format("opentype");
}

@font-face {
  font-family: "gotham-light";
  src: url("../public/fonts/Gotham-Light.otf") format("opentype");
}

@font-face {
  font-family: "gotham-bold";
  src: url("../public/fonts/Gotham-Medium.otf") format("opentype");
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  max-width: 100%;
  font-family: "gotham-light";
  font-weight: 325;
  letter-spacing: -0.02em;
}

button {
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: white;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

.app-transform:before {
  content: " ";
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 500px solid #ffea00;
}

.shape1 {
  position: absolute;
  top: 400px;
  right: 100px;
  border-radius: 100%;
  background-color: #9a55db;
  width: 200px;
  height: 200px;
  z-index: 2;
}

.shape2 {
  position: absolute;
  top: 300px;
  right: 0px;
  border-radius: 100%;
  background-color: #7c4ed8;
  width: 400px;
  height: 400px;
  z-index: 1;
}

.shape3 {
  position: absolute;
  top: 200px;
  right: -100px;
  border-radius: 100%;
  background-color: #5e4bdb;
  width: 600px;
  height: 600px;
  z-index: 0;
}

.perfil-anim-layer {
  transition: 0.5s all;
}

@media screen and (min-width: 768px) {
  .perfil-anim-layer {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

.perfil-anim-container:hover .perfil-anim-layer {
  transform: translateX(0);
  -webkit-transform: translateX(0);
}

.bg-sand {
  background-image: url("/public/assets/palmtree_2.jpg");
  background-size: cover;
  background-position: 0% 50%;
}

.icon-yellow {
  color: #ffb600;
}

.icon-blue {
  color: #00b8de;
}

.custom-searchbox input:focus {
  border: none !important;
  outline: none !important;
}
